import type { NextPage } from 'next';
import LoginContainer from 'authentication/containers/login';
import withAnon from 'authentication/components/WithAnon';
import { useEffect } from 'react';
import useTracking from 'core/hooks/useTracking';

const Home: NextPage = () => {
    const track = useTracking('LOGIN PAGE');

    useEffect(() => track.event('Views ATS Login Page'), []);

    return <LoginContainer isInitialLogin />;
};

export default withAnon(Home);
